import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  OrderPending: 0,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    onChangeOrderPending: (state, action) => {
      state.OrderPending = action.payload
    },
  },
})

export const { onChangeOrderPending } = orderSlice.actions

export default orderSlice.reducer
