import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  sidebarUnfoldable: false,
  theme: 'default',
}

export const defaultLayoutSlice = createSlice({
  name: 'default-layout',
  initialState,
  reducers: {
    onChangeSidabarShow: (state) => {
      state.sidebarShow = !state.sidebarShow
    },
    onChangeAsideShow: (state) => {
      state.asideShow = !state.asideShow
    },
    onChangeSidebarUnfoldable: (state) => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    onChangeTheme: (state) => {
      state.theme = !state.theme
    },
  },
})

export const { onChangeSidabarShow, onChangeAsideShow, onChangeSidebarUnfoldable, onChangeTheme } =
  defaultLayoutSlice.actions

export default defaultLayoutSlice.reducer
