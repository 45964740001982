import api from '../../../api'
import { persistor } from 'src/store'

const VerifyToken = async () => {
  await api.post('/checkToken', { token: localStorage.getItem('token') }).then(async (response) => {
    if (response.data.payload === false) {
      localStorage.removeItem('token')
      persistor.flush().then(() => {
        return persistor.purge()
      })
    }
  })
}

export default VerifyToken
