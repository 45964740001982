import axios from 'axios'

const api = axios.create({
  // baseURL: 'http://localhost:4999/',
  baseURL: 'https://api-easy3d.nextpoint.com.br/',
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token') ?? ''

  config.headers['Authorization'] = 'Bearer ' + token

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      console.error('Redirected to login by 4xx response!')
      window.location.href = '/'
    } else return Promise.reject(error)
  },
)

export default api
