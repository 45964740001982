import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const LoginPage = React.lazy(() => import('./pages/login'))
const ResetPassword = React.lazy(() => import('./pages/reset-password'))
const FirstAccessPage = React.lazy(() => import('./pages/first-access'))
const RegisterPage = React.lazy(() => import('./pages/register/register'))
const PtRegisterPage = React.lazy(() => import('./pages/register/pt/pt'))
const PortugalRegisterPage = React.lazy(() => import('./pages/register/portugal/portugal'))
const EnRegisterPage = React.lazy(() => import('./pages/register/en/en'))
const EsRegisterPage = React.lazy(() => import('./pages/register/es/es'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route exact path="/login" name="Login" element={<LoginPage />} />
            <Route
              exact
              path="/forgotPassword"
              name="Forgot Password"
              element={<ResetPassword />}
            />
            <Route exact path="/firstAccess" name="First Access" element={<FirstAccessPage />} />
            <Route exact path="/register" name="Register" element={<RegisterPage />} />
            <Route
              exact
              path="/pt/register/:country"
              name="Register PT"
              element={<PtRegisterPage />}
            />
            <Route
              exact
              path="/portugal/register/:country"
              name="Register Portugal"
              element={<PortugalRegisterPage />}
            />
            <Route
              exact
              path="/en/register/:country"
              name="Register EN"
              element={<EnRegisterPage />}
            />
            <Route
              exact
              path="/es/register/:country"
              name="Register ES"
              element={<EsRegisterPage />}
            />

            <Route exact path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
