import { configureStore } from '@reduxjs/toolkit'
import defaultReducer from './features/default-layout'
import userReducer from './features/user'
import orderSlice from './features/order'
import { clearResults } from './features/clear'
import VerifyToken from './helpers/verify/verify-token'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'

const persistConfig = {
  key: 'root',
  storage,
}

const appReducer = combineReducers({
  user: userReducer,
  order: orderSlice,
  defaultLayout: defaultReducer,
  clear: clearResults,
})

const rootReducer = (state, action) => {
  if (action.type === 'example/clearResults') {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root')

    state = {}
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

storage.getItem('token').then(async () => {
  await VerifyToken()
})

export const persistor = persistStore(store)
