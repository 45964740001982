import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userRole: 'User',
  isLogged:
    localStorage.getItem('token') !== null || localStorage.getItem('token') !== undefined
      ? true
      : false,
  userName: 'User',
  userEmail: '',
  userCountry: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onChangeUserRole: (state, action) => {
      state.userRole = action.payload
    },
    onLoggedIn: (state, action) => {
      state.isLogged = action.payload
    },
    setNewUserName: (state, action) => {
      state.userName = action.payload
    },
    setNewUserEmail: (state, action) => {
      state.userEmail = action.payload
    },
    setNewUserCountry: (state, action) => {
      state.userCountry = action.payload
    },
  },
})

export const { onChangeUserRole, onLoggedIn, setNewUserName, setNewUserEmail, setNewUserCountry } =
  userSlice.actions

export default userSlice.reducer
