import { createSlice } from '@reduxjs/toolkit'

const exampleSlice = createSlice({
  name: 'example',
  initialState: {
    v1: {},
  },
  reducers: {
    // ...
    clearResults() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
    },
  },
})

export const { clearResults } = exampleSlice.actions
